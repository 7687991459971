import React from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'

export default class DateField extends React.Component {
  render() {
    const label = this.props.label ? (
      <label className='label' htmlFor={this.props.name}>
        {this.props.label}
      </label>
    ) : (
      ''
    )
    const error = !this.props.error ? (
      <span className='control__error'>{this.props.errorMessage}</span>
    ) : (
      ''
    )
    return (
      <div className='control control-expanded'>
        {label}
        <DayPickerInput
          onDayChange={this.props.onChange}
          placeholder={this.props.placeholder}
          component={(props) => (
            <input
              {...props}
              className='input'
              id={this.props.name}
              name={this.props.name}
              placeholder={this.props.placeholder}
            />
          )}
        />
        {error}
      </div>
    )
  }
}
