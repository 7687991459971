import React from 'react';

import Layout from '@/components/Layout';
import { graphql, useStaticQuery } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import ContactForm from '@/components/Forms/Contact';

const ContactPageTemplate = ({ data }) => {
  return (
    <section className='pt-52  flex justify-center items-center contact-container mb-24'>
      <div className='container-lg'>
        <div className='flex flex-col items-start lg:flex-row justify-between'>
          <div
            className='max-w-xl w-full mt-10 mx-auto lg:mx-0 sticky'
            style={{ top: '64px', position: 'sticky' }}
          >
            <h1 className='text-h3 sm:text-h1 text-center lg:text-left'>
              {data.title}
            </h1>
            <ReactMarkdown
              className='contact__content mt-16 text-center lg:text-left'
              source={data.content}
            />
          </div>
          <div className='max-w-2xl mt-16 lg:mt-0 w-full flex mx-auto lg:mx-0 justify-center lg:justify-end'>
            <div className=' w-full m-0 shadow overflow-hidden rounded-md border-2 border-gray-lighter'>
              <iframe
                className='clickup-embed clickup-dynamic-height'
                src='https://forms.clickup.com/6965282/f/6mj12-94442/EDANFOGONTG0BUHZNM'
                onwheel=''
                width='100%'
                height='100%'
                style={{ height: '1400px' }}
              ></iframe>
              <script
                async
                src='https://app-cdn.clickup.com/assets/js/forms-embed/v1.js'
              ></script>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const ContactPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <ContactPageTemplate data={frontmatter} />
      {/* <ContactPageTemplate /> */}
    </Layout>
  );
};

export const pageQuery = graphql`
  query ContactPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "contact" } }) {
      frontmatter {
        title
        content
        seo {
          title
        }
      }
    }
  }
`;

export default ContactPage;
